<template>
    <v-row style="background-color: #016fc7;" class="pt-5 px-5">
        <v-col :cols="porcento ? 9 : 12" class="px-0">
            <h1>{{titulo}}<br /><strong>{{subtitulo}}</strong></h1>
            <v-divider class="mt-3" style="background-color: #FFD600;"/>
        </v-col>
        <v-col cols="3" class="px-0" v-if="porcento">
            <v-progress-circular style=" float:right; background-color: #005FAB; border-radius: 50%; box-shadow: 1px 1px 1px #555;" :rotate="360" :size="82" :width="15" :value="porcento" color="#FFD600">
                <strong style="font-size: 11px;">{{porcento}}%</strong>
            </v-progress-circular>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "HeaderAzul",
    props: ["titulo", "subtitulo", "porcento"]
}
</script>

<style scoped>
    h1 {text-align: left; width: 100%;  font-weight: lighter; font-size: 30px; color: #FFFFFF; line-height: 120%;}
    h1 strong {font-weight: bold;}
</style>