<template>
    <v-container fluid class="pt-0 mt-0">
        <HeaderAzul v-if="aula.conteudo != undefined" :titulo="aula.curso" :subtitulo="aula.turma" :porcento="null"/>
        <v-row style="background-color: #005FAB;" cols="12" v-if="aula.conteudo != undefined">
            <v-col class="px-5 py-0">
                <p style="color: #FFFFFF;">
                    <strong>Professor:</strong> {{aula.usuario_professor}} -
                    <strong>Level:</strong> {{aula.level}}
                </p>
            </v-col>
        </v-row>
        <v-row class="justify-center mt-3">
            <v-col cols="12">
                <!-- Sem presença -->
                <v-card elevation="0" v-if="!aula.presente && !usuarioSessao.embed && Object.keys(aula).length > 0">
                    <v-card-title style="color: #00436F">Aula {{aula.aula_numero}}</v-card-title>
                    <v-divider/>
                    <v-card-text class="pb-3" style="color: #333; font-size: 18px; line-height: 160%;">
                        <p class="mb-0"><strong>Professor:</strong> {{aula.usuario_professor}}</p>
                        <p class="mb-0"><strong>Início:</strong> {{dataPt(aula.datahora_inicio)}}</p>
                        <p class="mb-0"><strong>Fim:</strong> {{dataPt(aula.datahora_fim)}}</p>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions><v-spacer/><v-btn text @click="aulaAtualRegistrarPresenca">Registrar presença e iniciar aula</v-btn></v-card-actions>
                </v-card>
                <v-alert v-if="erro">{{erro}}</v-alert>
                <!-- Com presença -->
                <v-card elevation="0" color="#00436F" v-if="aula.presente">
                    <v-card-title style="color: #FFFFFF; font-size: 25px;" class="pb-0 pt-3">
                        {{aula.conteudo ? aula.conteudo.conteudo : 'Aguardando conteúdo...'}}
                        <v-spacer/>
                        <v-btn @click="dialogExibir({name : 'livroDialog', id_curso : parseInt(aula.id_curso), id_conteudo : parseInt(aula.conteudo.id_conteudo)})" v-if="aula.conteudo" icon><v-icon color="#FFF">mdi-book-open-page-variant</v-icon></v-btn>
                    </v-card-title>
                </v-card>
            </v-col>
            <template v-if="(aula.presente == true && aula.conteudo) || usuarioSessao.embed">
                <template v-for="(cf, i) in aula.conteudo_frase">
                    <v-col v-if="cf.sei == false" cols="12" md="6" lg="4" xl="3" :key="i">
                        <v-card>
                            <v-card-text class="pt-3 my-0" v-html="`${i+1}. ${cf.frase}`" style="white-space: pre-line; color: #000;"/>
                            <v-divider/>
                            <v-card-text v-show="cf.resposta_exibir" v-html="cf.resposta" style="white-space: pre-line;"></v-card-text>
                            <v-divider v-show="cf.resposta_exibir"/>
                            <v-card-actions>
                                <v-btn v-if="cf.sei == false" text @click="cf.sei = true;sei(cf.id_frase)"><v-icon left>mdi-check</v-icon> Eu sei</v-btn>
                                <v-spacer/>
                                <v-btn text @click="cf.sei = cf.resposta_exibir && cf.sei == true ? true : false;cf.resposta_exibir = !cf.resposta_exibir;naoSei(cf.id_frase, cf.resposta_exibir)"><v-icon left>mdi-eye</v-icon> {{!cf.resposta_exibir ? 'Traduzir' : 'Ocultar'}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </template>
                <template v-for="(cf, i) in aula.conteudo_frase">
                    <v-col v-if="cf.sei == true" cols="12" md="6" lg="4" xl="3" :key="'s_'+i">
                        <v-card color="green" dark>
                            <v-card-text class="pt-3 my-0" v-html="`${i+1}. ${cf.frase}`" style="white-space: pre-line; color: #FFF;"/>
                            <v-divider style="border-color: #FFFFFF33"/>
                            <v-card-actions>
                                <v-btn v-if="cf.sei == false" text @click="cf.sei = true;sei(cf.id_frase)"><v-icon left>mdi-check</v-icon> Eu sei</v-btn>
                                <v-spacer/>
                                <v-btn text @click="cf.sei = cf.resposta_exibir && cf.sei == true ? true : false;cf.resposta_exibir = !cf.resposta_exibir;naoSei(cf.id_frase, cf.resposta_exibir)"><v-icon left>mdi-eye</v-icon> {{!cf.resposta_exibir ? 'Traduzir' : 'Ocultar'}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </template>
            </template>
        </v-row>
    </v-container>
</template>

<script>

import axios from "axios"
import {mapMutations, mapState} from "vuex"
import HeaderAzul from "@/Views/Estrutura/HeaderAzul"
import Pusher from "pusher-js"

export default {
    name: "CursoAulaAtual",
    components: {HeaderAzul},
    computed : {
        ...mapState(['usuarioChave', 'apiUrl', 'baseUrl', 'usuarioSessao'])
    },
    data: () => ({
        curso : {},
        aula : {},
        initChamado : false,
        interval : null,
        pusher : null,
        erro : null
    }),
    methods: {
        ...mapMutations(['dialogExibir']),
        getAulaAtual() {
            let id_level = null
            if (this.usuarioSessao.id_level) {
                id_level = this.usuarioSessao.id_level
            }
            return axios.post(`${this.apiUrl}aula/atual/get`, {
                id_turma : parseInt(this.curso.id_turma),
                id_curso : parseInt(this.curso.id_curso),
                embed    : this.usuarioSessao.embed || false,
                id_level : id_level
            }).then( (res) => {
                if (res.data) {
                    let dados = res.data
                    dados.conteudo_frase = dados.conteudo_frase != null ? dados.conteudo_frase.map((v) => {return {...v, resposta_exibir : false}}) : null
                    if(dados.conteudo == null || !this.aula.conteudo || this.aula.conteudo.id_conteudo != dados.conteudo.id_conteudo){
                        this.aula = dados
                    }
                }
                this.erro = null
            }).catch(e => {
                this.erro = this.erroFormatar(e)
                this.carregando = false
            })
        },
        aulaAtualRegistrarPresenca() {
            return axios.post(`${this.baseUrl}aula/aluno_registrar_presenca`, {id_aula : this.aula.id_aula}).then( (res) => {
                this.aula = res.data
                this.getAulaAtual()
            })
        },
        sei(id_frase) {
            axios.post(`${this.baseUrl}aula/conteudo_frase_sei`, {id_aula:this.aula.id_aula, id_turma : this.curso.id_turma, id_curso : this.curso.id_curso, id_conteudo : this.aula.conteudo.id_conteudo, id_frase, id_level : this.aula.id_level})
        },
        naoSei(id_frase, exibir) {
            if (exibir) {
                axios.post(`${this.baseUrl}aula/conteudo_frase_nao_sei`, {id_aula:this.aula.id_aula, id_turma : this.curso.id_turma, id_curso : this.curso.id_curso, id_conteudo : this.aula.conteudo.id_conteudo, id_frase, id_level : this.aula.id_level})
            }
        },
        pusherInit() {
            // this.pusher = new Pusher('EX3J2ciYmGvX1s5xMrU5', {
            //     wsHost: 'excellentglobal.especie.com.br',
            //     wsPort: 443,
            //     forceTLS: true,
            //     encrypted: true,
            //     disableStats: true,
            //     enabledTransports: ['ws', 'wss'],
            // })
            this.pusher = new Pusher('f90e37efa7f922ebcab9', {
                cluster: 'sa1'
            })
            let aula_atual = this.pusher.subscribe(`aula_atual_${this.curso.id_turma}`)
            aula_atual.bind(`atualizar_app`, () => {
                this.getAulaAtual()
            })
        },
        async init() {
            const json = localStorage.getItem(this.usuarioChave);
            const sessao = JSON.parse(json);
            if (!sessao.id_turma) {
                this.$router.push('/cursos')
            }
            this.curso = {
                id_curso : sessao.id_curso,
                id_turma : sessao.id_turma
            }
            await this.getAulaAtual()
        }
    },
    activated() {
        if(!this.initChamado) {
            this.initChamado = true
            this.init()
        }
        this.pusherInit()
    },
    deactivated() {
        this.initChamado = false
        this.pusher.disconnect()
    }
}
</script>

<style scoped>

</style>